<div class="audit-header">
  <div [ngClass] = "{hidding: editable}" class="tips">Shared Template View Only</div>
  <div class="button-row">
    <button mat-button class="icon-only"
            (click)="backToHome()"
            matTooltip="Back to RegApply home"
            matTooltipClass="tooltip1">
            <mat-icon>west</mat-icon>
    </button>
    <button class="normal-button"
      matTooltip="Save template" matTooltipClass="tooltip1"
      mat-button [disabled]="this.isLoading || !this.editable"
      (click)="onSaveTemplate()"><mat-icon>save</mat-icon>
    </button>
    <button class="normal-button"
    matTooltip="delete template" matTooltipClass="tooltip1"
      mat-button [disabled]="csskey < 1 || this.isLoading || !this.editable"
      (click)="onDeleteTemplate()"><mat-icon>delete</mat-icon>
    </button>
    <button class="normal-button"
        matTooltip="Answer applicability screening questions"
        matTooltipClass="tooltip1"
        mat-button [disabled]="csskey < 1 || this.isLoading"
        (click)="onBuildApplicability()"><mat-icon>assessment</mat-icon>
    </button>
    <button class="normal-button"
      matTooltip="Add to Outlook calendar" matTooltipClass="tooltip1"
      mat-button [disabled]="this.isLoading || csskey < 1"
      (click)="onAddEventToCalendar()"><mat-icon>calendar_month</mat-icon>
    </button>
  </div>
</div>
<div class="contents">
  <mat-card class="template-header" [formGroup]="templateHead">
      <mat-card-title class="mat-primary mat-toolbar">Audit Details</mat-card-title>

      <mat-card-content class="large">
        <mat-form-field class="one-row">
          <input matInput placeholder="Audit Name" formControlName="auditName" maxlength="255" required>
          <mat-error *ngIf="templateHead.get('auditName').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>

        <div class="one-row share-line">
          <mat-icon class="help-info" (click)="openHelpInfo($event, 4)">info</mat-icon>
          <span class="share-checkbox">Share template: </span>
          <mat-radio-group class="share-template" [(ngModel)]="permission" [ngModelOptions]="{standalone:true}" color="primary">
            <mat-radio-button color="primary" value="Not" [disabled]="this.isLoading || !this.editable" [checked] = "permission == 'Not'">No</mat-radio-button>
            <mat-radio-button color="primary" value="View" [disabled]="this.isLoading || !this.editable" [checked] = "permission == 'View'">View</mat-radio-button>
            <mat-radio-button color="primary" value="Edit" [disabled]="this.isLoading || !this.editable" [checked] = "permission == 'Edit'">Edit</mat-radio-button>
          </mat-radio-group>
        </div>

        <div fxLayout="row" fxLayoutGap="15px">
          <mat-form-field fxFlex="calc(50%-15px)">
              <input (dateInput)="OnStartDateChange($event.value)"
              [disabled]="this.isLoading || !this.editable"
                matInput [matDatepicker]="picker1"
                placeholder="Audit Start Date" formControlName="startDate">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1 color="primary"></mat-datepicker>
          </mat-form-field>

          <mat-form-field fxFlex="calc(50%-15px)">
              <input
                matInput [matDatepicker]="picker2" [min]="startDateValue"
                [disabled]="this.isLoading || !this.editable"
                placeholder="Audit End Date" formControlName="endDate">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2 [startAt]="startDateValue" color="red"></mat-datepicker>
          </mat-form-field>
        </div>
        <mat-form-field class="one-row">
            <input matInput placeholder="Facility" maxlength="50" formControlName="facilityName">
        </mat-form-field>

        <div class="one-row">
            <AutocompleteComponent (setAddress)="getAddress($event)" addressType="geocode" [address]="formattedAddress"></AutocompleteComponent>
        </div>
        <div #mapContainer id="map"></div>
      </mat-card-content>
  </mat-card>

  <div class="topic-options">
    <div class="audit-rb">
      <ul class="nav nav-tabs">
          <li *ngFor="let ca of topics" [class]="(ca.category==currentCategory)? 'none' : 'panel-tab'" >
              <a
                [routerLink]=""
                [class.disabled]="this.isLoading"
                [class]="(ca.category==currentCategory)? 'selected' : 'none'"
                (click)="onCategoryChange(ca.category)">
                {{mapCategory(ca.category)}}
                <span *ngIf="ca.selected > 0" class="badger-number" matBadge="{{ca.selected}}" matBadgeOverlap="false" [matBadgeColor]="'warn'"></span>
              </a>
          </li>
      </ul>
    </div>
    <div *ngFor="let ca of topics" class="topic-contents">
        <div *ngIf="ca.category == currentCategory" class="accordion-item">
          <mat-accordion class="accordion-item"  *ngFor="let ac of ca.acronyms; let i = index">
              <mat-expansion-panel  class="accordion-content"
                [disabled]="this.isLoading"
                [expanded]="step === i"
                (opened)="setStep(i, ac.acronym)" hideToggle>
                  <mat-expansion-panel-header style="height: 48px;">
                    <mat-panel-title>
                      {{ac.title}}
                      <ng-container *ngIf = "ca.category !== 'Custom'">
                        ({{ac.releaseNum}})
                      </ng-container>
                      <ng-container *ngIf = "ac.acronym === 'FIRE'">
                        <a href="publications/OSHA/FIRE/FIRE_Intro.pdf" target="_blank">&nbsp;View State Adoption</a><span style="color: red;">*</span>
                      </ng-container>
                    </mat-panel-title>
                    <mat-panel-description class="badger-icon">
                        <mat-checkbox *ngIf="step === i" class="topic-check" [checked]="ac.topics ? ac.selected === ac.topics.length : false"
                        (click)="onSelectAllClick($event)"
                        (change)="onSelectAllChange($event, ac.acronym)" [disabled]="this.isLoading || !this.editable">Select All
                        </mat-checkbox>
                      <span class="badger-number" matBadge="{{ac.selected}}" matBadgeOverlap="false" [matBadgeColor]="(ac.selected != 0) ? 'warn' : 'primary'"></span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <ng-template [ngIf]="step === i">
                    <div class="checkbox-container">
                      <mat-checkbox *ngFor="let tp of ac.topics" class="topic-check" [(ngModel)]="tp.selected" [disabled]="this.isLoading || !this.editable"
                      (change)="onTopicChange($event, tp, ac.title)">{{tp.topic}}</mat-checkbox>
                    </div>
                  </ng-template>
                </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
  </div>
</div>
<div class="spinner-container" *ngIf="service.loading">
  <mat-spinner></mat-spinner>
</div>