import { Component, OnInit, ChangeDetectorRef, ElementRef,  Inject, Input } from '@angular/core';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Emis} from '../../_constants/Emis';
import {TemplateService} from '../service/template.service';
import { TemplateContent, TemplateRulebooks, Rb, SectContents, ParaContents} from '../model/TemplateRulebook';
import { AlertifyService } from '../../_models/alertify.service';
import { HelpInfoDialog} from '../../_components/header/header.component';
import  { AuthService} from '../../auth/auth.service';
import { Account } from '../../_models';
import {MatRadioChange} from '@angular/material/radio'

/**
 *
 */
declare let alertify: any;
@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.css']
})
export class EditTemplateComponent implements OnInit {
  csskey: string;
  selectedAcronym: string;
  auditName: string;
  percentage: string;
  account: Account;
  facilityLocation: string;
  auditDate: string;

  rulebooks: TemplateContent[];
  auditPrint$ = new  Subject<TemplateContent[]>();
  emisid = 0; //EMIS parter id
  editable = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public service: TemplateService,
    private alertifyService: AlertifyService,
    private cdr: ChangeDetectorRef,
    public dialog:MatDialog
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(
      paraMap => {
        this.csskey = paraMap.get('id')
      }
    );
    this.account = AuthService.currentUser;
    this.auditName = this.service.getAuditName();
    this.facilityLocation = this.service.getFacilityLocation();
    this.auditDate = this.service.getAuditDate();
    this.emisid = this.account.emis ?? 0;
    this.service.updateTemplateDateStorage(+this.csskey); // update its cssdate in the session storage.
    this.editable = this.service.isTemplateEditable(+this.csskey)

    // Check if the rulebooks of this template have been cached.
    let cached = this.service.getRulebookLocalStorage(parseInt(this.csskey));
    if(cached != null && cached.length > 0){
      this.service.touchTemplate(this.csskey);
      this.populateRulebook(cached);
    }else{
      this.service.showLoadingAnimation(true);
      this.service.getContent(+this.csskey).subscribe(
        res => {
          this.service.showLoadingAnimation(false);
          this.service.cacheRulebookLocalStorage(parseInt(this.csskey), res);
          this.populateRulebook(res);
        },
        err =>{
          console.error("got an error:" + err.error);
          this.service.showLoadingAnimation(false);
          this.alertifyService.error(err.error);
        }
      );
    }
  }

  reload(){
    this.service.removeTemplateLocalStorage(+this.csskey);

    this.service.showLoadingAnimation(true);
      this.service.getContent(+this.csskey).subscribe(
        res => {
          this.service.showLoadingAnimation(false);
          this.service.cacheRulebookLocalStorage(parseInt(this.csskey), res);
          this.populateRulebook(res);
        },
        err =>{
          console.error("got an error:" + err.error);
          this.service.showLoadingAnimation(false);
          this.alertifyService.error(err.error);
        }
      );
  }

  getEmis():string{
    if(this.emisid == 0) return "";
    const found = Emis.find(element => element.value == this.emisid);
    if(found === undefined) return "";
    return found.name;
  }

  getDemoBuildType(email: string): string {
    if(email.toLowerCase() == "processmap-demo@stpub.com")
      return "Ideagen EHS";
    else
      return email.substring(0, email.indexOf('-demo'));
  }

  backToHome(){
    this.router.navigateByUrl("/audithub/apptool/list");
  }


  // "88.8%"" -> 0.888
  convertPercentage(percentage: string){
    if(percentage){
      return parseFloat(percentage.replace("%", ""))/100;
    }
    return null;
  }


  // Populate the page with rulebook data of the tempalte.
  populateRulebook(res: TemplateContent[]): void {
    var questionSumTemplate = 0;
    var answeredSumTemplate = 0;

    // Set the completing percentage of rulebook, acronym and template at the frontend, not the backend.
    res.forEach(acronym => {
      var questionSumAcronym = 0;
      var answeredSumAconrym = 0;

      acronym.topics.forEach(tp => {
        tp.topic = this.service.decodeHtml(tp.topic);
        tp.rulebooks.forEach(rb => {
          let {sum, answered} = this.service.calQuestionSum(rb.appAnswer);
          rb.percentage = this.service.calPercentage(answered, sum);
          rb.rbName = this.service.decodeHtml(rb.rbName);
          rb.checkNa = rb.checkNa ? rb.checkNa: this.service.calCheckNa(rb.appAnswer);
          rb.status = this.checkRulebookStatus(rb);
          if(rb.checkNa == false){ // We do not count in rulebooks marked as CheckNA
                questionSumAcronym += sum;
                answeredSumAconrym += answered;
          }
          if(rb.abridged){ // For abridged rulebooks, making fake question and answer to reflect the percentage=> 1/1 = 100%
                questionSumAcronym++;
                answeredSumAconrym++;
              }
          })
        })

        if(questionSumAcronym == 0) {
            acronym.percentage = "N/A";
        }else{
        acronym.percentage = this.service.calPercentage(answeredSumAconrym, questionSumAcronym);
      }
      questionSumTemplate += questionSumAcronym;
      answeredSumTemplate += answeredSumAconrym;
    })
    this.percentage = this.service.calPercentage(answeredSumTemplate, questionSumTemplate).replace("%","");
    this.rulebooks = res;
    this.selectedAcronym = res[0].acronym;
    this.service.setCurrent(res);
  }


   // click on button print audit
   printAudit(){
    this.service.getAuditPrint(+this.csskey).subscribe(res =>{
      for(let acms of res){
        for(let tps of acms.topics){
          for(let rbs of tps.rulebooks){
            for(let apps of rbs.appSect){
             apps.question =  this.service.decodeHtml(apps.question);
             apps.exemption = this.service.decodeHtml(apps.exemption);
            }
          }
        }
      }
      this.auditPrint$.next(res);
      this.cdr.detectChanges();// Force refreshing the page to load the data for printing.
      },
      err => console.error("got an error:" + err),
      () => {window.print();console.log("end print");}
    );
  }

  // Return the first applicable rulebook id of this template.
  getFirstApplicableRulebook():number{
    let acNumber = this.rulebooks.length;

    for(let i = 0; i < this.rulebooks.length; i++){
      for(let j = 0; j < this.rulebooks[i].topics.length; j++){
        for(let k = 0; k < this.rulebooks[i].topics[j].rulebooks.length; k++){
          if(this.rulebooks[i].topics[j].rulebooks[k].checkNa == false)
            return this.rulebooks[i].topics[j].rulebooks[k].rbKey;
        }
      }
    }
    return null;
  }

  // Determine the status of a rulebook according to its applicability answers
  checkRulebookStatus(rb: Rb){
    if(rb.needsReview){
      return "Needs Review";
    }

    if(rb.checkNa){
      return "Not Applicable";
    }

    if(rb.done || this.isDone(rb)){
      return "Applicable";
    }else{
      return "Incomplete";
    }
  }

  // To determine if the questions are all answered.
  isDone(rb: Rb): boolean{
    if(!rb.appAnswer) return false;
    for(var x = 0; x < rb.appAnswer.length; x++){
      let c = rb.appAnswer.charAt(x);

      if(c == 'I') // unanswered question
        return false;
      if(c == 'i' && rb.appAnswer.charAt(x - 1) == 'Y') // unanswered excemption and the answer to the question is Yes.
        return false;
    }
    return true;
  }

  onAcronymChange(acronym:string){
    this.selectedAcronym = acronym;
  }

  onRbClick(appkey: number, rbName: string, abridged: boolean){
    if(abridged){
      this.alertifyService.confirmOk("Abridged Protocols do not have applicability questions. The rulebook questions will be included in your Xcelerator automatically.");
      return false;
    }
    this.changeRulebook(rbName);
    this.getApplicability(appkey);
  }

  removeCheckUpdatFlag(csskey: number, appkey: number){
    var myurl = '';
  }

  onAddRulebook(event){
    var myurl = '/audithub/apptool/new/' + this.csskey;
    this.router.navigateByUrl(myurl).then(nav => {
      console.log("Navigate to: " + myurl);
    }, err =>{
      console.log(err);
    });
  }

  // change event on checkbox of Check N/A on rulebook
  onCheckNaChange(event, key:number){
    this.service.showLoadingAnimation(true);
    this.service.checkNa(+this.csskey, key, event.checked).subscribe(
      res  => {
        this.updateRulebookStatus(key, event.checked);
      },
      err => console.error("got an error:" + err),
      () => {
        this.service.showLoadingAnimation(false);
      }
    );
  }

  /**
   * Review the section and paragraph difference under a rulebook after the template updated from one version to another.
   * @param event
   * @param appkey
   */
  onReviewUpdate(event: any, appkey: number){
    event.stopPropagation();
    this.service.reviewUpdate(parseInt(this.csskey), appkey).subscribe(
      res => {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = res;
        dialogConfig.width = '800px';
        const dialogRef = this.dialog.open(DisplayReviewDialog, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          if(result){ // Remove the review flag.
            // Update local cache, set 'checkUpdate = false' for this rulebook.
            this.service.revokeReviewFlag(parseInt(this.csskey), appkey, 2);
            //Refresh the page to not display the flag
            let cached = this.service.getRulebookLocalStorage(parseInt(this.csskey));
            if(cached != null && cached.length > 0){
              this.service.touchTemplate(this.csskey);
              this.populateRulebook(cached);
            }
            // Call backend to remove the 'checkUpdate' flag
            this.service.removeCheckUpdateFlag(+this.csskey, appkey).subscribe(
              res  =>{
                console.log("success.");
              },
              err => {
                console.log("backend return error: " + err);
                this.alertifyService.error(err.error);
              }
            );
          }
        });
      },
      err =>{
        console.error(err);
        this.alertifyService.error(err.error);
      }
    );
  }


  // After call backend to set flag of checkNa, update the view
  updateRulebookStatus(appkey: number, checkNa:boolean){
    var questionSumTemplate = 0;
    var answeredSumTemplate = 0;

    this.rulebooks.forEach(acronym => {
      var questionSumAcronym = 0;
      var answeredSumAconrym = 0;

      acronym.topics.forEach(tp => {
        tp.topic = this.service.decodeHtml(tp.topic);
        tp.rulebooks.forEach(rb =>{
          if(rb.appKey == appkey){
            rb.checkNa = checkNa;
            rb.status = this.checkRulebookStatus(rb);
          }

          let {sum, answered} = this.service.calQuestionSum(rb.appAnswer);
          rb.percentage = this.service.calPercentage(answered, sum);
          rb.rbName = this.service.decodeHtml(rb.rbName);
          rb.status = this.checkRulebookStatus(rb);
          if(rb.checkNa == false){
            questionSumAcronym += sum;
            answeredSumAconrym += answered;
            //rulebookCount++;
            //acronymCompleted += this.convertPercentage(rb.percentage);
          } else{
            rb.percentage = "N/A";
          }
        })
      })

      acronym.percentage = this.service.calPercentage(answeredSumAconrym, questionSumAcronym);
      questionSumTemplate += questionSumAcronym;
      answeredSumTemplate += answeredSumAconrym;
    });
    //sessionStorage.setItem('template', JSON.stringify(this.rulebooks));// update the session storage.
    this.service.cacheRulebookLocalStorage(parseInt(this.csskey),this.rulebooks);
    this.percentage = this.service.calPercentage(answeredSumTemplate, questionSumTemplate).replace("%","");
  }

  // To check if the template contains protocols that might have state difference.(Only EAF or OF has SD)
  hasStateDifference(): boolean {
    let permission = sessionStorage.getItem("auditHubPermissions");
    if(permission == null || permission == undefined) return false;

    let eafSd = JSON.parse(permission).eaf;
    let ofSd = JSON.parse(permission).of;

    for (var i = 0; i < this.rulebooks.length; i++){
      if(this.rulebooks[i].acronym == "EAF" && eafSd && eafSd.trim().length > 0){
        return true;
      }
      if(this.rulebooks[i].acronym == "OF" && ofSd && ofSd.trim().length > 0){
        return true;
      }
    }
    return false;
  }

  /**
   * Open the dialog to display help information.
   * @param $event: click mouse event
   * @param index: the index of the help info. See ./_constants/AppSettting for more detail.
   */
  openHelpInfo($evt: MouseEvent, index: number): void {
     $evt.stopPropagation();
     const target = new ElementRef($evt.currentTarget);
     const dialogRef = this.dialog.open(HelpInfoDialog, {
      width: '280px',
      data: {
        trigger: target,
        helperIndex: index
      }
    });
  }

  buildXcelerator(buildType: string, answer: string, states?: string[], ){
    try {
      this.validateBeforeBuild();
      if(buildType.toLowerCase() == "no"){
        this.alertifyService.confirmOk("<p>Your audit template has been sent to your EHS MIS provider.  STP audit template conversions take 1-2 days.  Your EHS MIS provider may also need time to integrate your template.</p><p>Please contact us at <b>info@stpub.com</b> if you have any questions.</p>");
        return;
      }
      this.service.showLoadingAnimation(true);
      this.service.buildXcelerator(+this.csskey, buildType, answer, states ).subscribe(
        res  => {
          this.service.showLoadingAnimation(false);
          if(buildType === "X3.0" || buildType === "X3.1"){
            this.alertifyService.confirmOk("<p>Xcelerator file can take a while to prepare.</p><p>Once it's completed we'll send you an email at: " + this.service.getLoginEmail()+"</p>");
          } else if( buildType === "C3.0"){
            this.alertifyService.confirmOk("<p>Applicable Citations file can take a while to prepare.</p><p>Once it's completed we'll send you an email at: " + this.service.getLoginEmail()+"</p>");
          } else if( buildType === "Vector"){
            this.alertifyService.confirmOk("<p>Vector file can take a while to prepare.</p><p>Once it's completed we'll send you an email at: " + this.service.getLoginEmail()+"</p>");
          } else if(buildType === "reghub"){
            this.alertifyService.confirmOk("<p>RegRegister monitor has been set for the template.</p>");
          } else if(buildType === "csv"){
            this.alertifyService.confirmOk("<p>CSV file can take a while to prepare.</p><p>Once it's completed we'll send you an email at: " + this.service.getLoginEmail()+"</p>");
          } else if(buildType.toLowerCase() == "intelex"){
            this.alertifyService.confirmOk("<p>Your audit template has been sent to Intelex. You will receive a confirmation e-mail once the template is ready.</p><p>Please contact us at <b>info@stpub.com</b> if you have any questions.</p> <img src='../../../assets/images/intelex-logo.png'>");
          }else if(buildType.toLowerCase() == "safetystr"){
            this.alertifyService.confirmOk("<p>Your audit template has been sent to SafetyStratus for processing.  The audit template conversion and integration will take approximately 24 hours.</p><p>Please contact us at <b>info@stpub.com</b> if you have any questions.</p><img src='../../../assets/images/safetystr-logo.svg'>");
          }
          else if(buildType.toLowerCase() == "velocity"){
            this.alertifyService.confirmOk("<p>Your audit template is now in the formatting queue, and you will receive a confirmation email once the file is ready.</p><p>VelocityEHS may need up to 5 days to integrate your template.</p><p>Please contact us at <b>info@stpub.com</b> if you have any questions.</p><br><img style='background-color:#3F37C9' src='../../../assets/images/velocityehs-logo.svg'>");
          }else if(buildType.toLowerCase() == "processmap"){
            this.alertifyService.confirmOk("<p>Your audit template is now in the formatting queue, and you will receive a confirmation email once the file is ready.</p><p>Ideagen EHS may need 2-3 days to integrate your template.</p><p>Please contact us at <b>info@stpub.com</b> if you have any questions.</p><br><img src='../../../assets/images/processmap-logo.svg'>");
          }
          else{
            this.alertifyService.confirmOk("<p>Your audit template has been sent to your EHS MIS provider.  STP audit template conversions take 1-2 days.  Your EHS MIS provider may also need time to integrate your template.</p><p>Please contact us at <b>info@stpub.com</b> if you have any questions.</p>");
          }
        },
        err => {
          this.service.showLoadingAnimation(false);
          console.log("backend return error: " + err);
          this.alertifyService.error(err.error);
        },
        () => {
          this.service.showLoadingAnimation(false);
        }
      );
    }catch(Error){
      this.service.showLoadingAnimation(false);
      this.alertifyService.error(Error.mesage);
    }
  }


  // Event listener on button 'build'
  async onBuild(event, buildType: string){
    if(!isNaN(parseInt(buildType))){
      buildType = this.getEmis();
    }
    var answer = "";
    if(buildType.toLowerCase() === "intelex"){
      if(this.service.getIntelexFlag()){
        this.alertifyService.confirmOk("You have already sent this template to Intelex.  Request ignored.");
        return
      }
      try{
        this.service.showLoadingAnimation(true);
        var answers =  await this.getAnswerList();
        this.service.showLoadingAnimation(false);
        const result = await this.openPreAnswerDialog(answers);

        if(result === null){
          return;
        }
        answer = result;
      }catch(error){
        this.alertifyService.error(error);
        return;
      }
    }
    if(buildType.toLowerCase() == "safetystratus") buildType = "SAFETYSTR";
    if(buildType.toLowerCase() == "ideagen ehs") buildType = "processmap";

    if(this.hasStateDifference()){
      let state = this.service.getFavoriteState(this.csskey);

      const authorized_state =  this.getAuthorizedState();
      if(authorized_state.length > 0){
        const result = await this.openStateListDialog(state, buildType, authorized_state);
        if(result === null){
          return false;
        }
        if(result.length > 0) {
          if(result[result.length - 1] == '0' || result[result.length - 1] == '1'){ // Xcelerator unlock/lock
            if(result[result.length - 1] ==  '1'){
              buildType = "X3.1";
            }
            result.pop();
          }
          if(result.length > 0){
            this.service.cacheFavoriteState(this.csskey, result[0]); // Save the first state as the favoriate for the future useage.
            this.buildXcelerator(buildType, answer, result);
            return false;
          }
        }
        this.buildXcelerator(buildType, answer);
      }
    }else{
      const result = await this.openConfirmDialog(buildType);

      if(result === -1){
        return false;
      }
      if(result < 2) {
        this.buildXcelerator(buildType, answer);
        return false;
      }else{
        this.buildXcelerator("X3.1", answer);
      }
    }
  }

  getAuthorizedState(): string[]{
    var authorized_state: string[] = [];
    let permission = sessionStorage.getItem("auditHubPermissions");
    if(permission == null || permission == undefined) return authorized_state;

    let eafSd = JSON.parse(permission).eaf;
    let ofSd = JSON.parse(permission).of;

    if(eafSd && eafSd.trim().length > 0){
      authorized_state = authorized_state.concat(eafSd.toUpperCase().split(","));
    }
    if(ofSd && ofSd.trim().length > 0){
      authorized_state = authorized_state.concat(ofSd.toUpperCase().split(","));
    }
    if(authorized_state.length == 0) return authorized_state;
    return authorized_state.filter((v,i,a) => a.indexOf(v) === i).sort();
  }

  async openConfirmDialog(buildType: string): Promise<number> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '480px';
    dialogConfig.panelClass = 'confirm-dialog';
    dialogConfig.data = {
      build: buildType
    };

    const dialogRef = this.dialog.open(ConfirmBuildDialog, dialogConfig);
    return  dialogRef.afterClosed()
    .toPromise()
    .then(result => {
      return Promise.resolve(result);
    });
  }

  async openPreAnswerDialog(answers:string[]): Promise<string> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
            answers: answers
    };
    dialogConfig.width = '800px';
    const dialogRef = this.dialog.open(PreAnswerListDialog, dialogConfig);
    return dialogRef.afterClosed()
    .toPromise()
    .then(result => {
      return Promise.resolve(result);
    });
  }

  async openStateListDialog(state: string, buildType: string, authorized_state:string[]): Promise<string[]> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'confirm-dialog';
    dialogConfig.data = {
      selected: state || "",
      buildType: buildType,
      states: authorized_state
    };
    dialogConfig.width = '800px';
    const dialogRef = this.dialog.open(StateListDialog, dialogConfig);

    return dialogRef.afterClosed()
    .toPromise()
    .then(result => {
      return Promise.resolve(result);
    });
  }

  async getAnswerList(){
    const source$ = this.service.getIntelexAnswer();
    var response = await lastValueFrom(source$);
    if (response) {
        return response.answer;
    }
  }


  // on clicking button 'View Rulebooks'
  onViewRulebooks(){
    console.log("on View Rulebook.");
    let rbkey = this.getFirstApplicableRulebook();

    if(rbkey !== null){
      var myurl = '/audithub/apptool/viewRulebooks/' + this.csskey + '/' + rbkey + "|" + encodeURIComponent(this.auditName);
      this.router.navigateByUrl(myurl).then(nav => {
        console.log("Navigate to: " + myurl);
      } , err => {
        console.log(err);
      });
    }else{
      alert("There is no applicable rulebook to view!");
    }
  }

  // on clicking button 'View RegRegiser'
  onViewRegRegister(){
      var myurl = '/audithub/apptool/viewCitations/' + this.csskey + "|" + this.auditName;
      this.router.navigateByUrl(myurl).then(nav => {
        console.log("Navigate to: " + myurl);
      } , err =>{
        console.log(err);
      });
  }

  validateBeforeBuild():boolean {
    if(this.csskey != '23')
      return true;
    throw new Error("cannot build.");
  }

  changeRulebook(rbName: string){
    this.service.setRbName(rbName);
  }

  getApplicability(appkey: number){
    var myurl = '/audithub/apptool/applicability/' + this.csskey + '/' + appkey;
    this.router.navigateByUrl(myurl).then(nav => {
      console.log("getApplicability() navigate to: " + myurl);
    }, err => {
      console.log(err);
    });
  }
}

/**
 * Dialog window to display the difference of rulebook after updating.
 */
@Component({
  selector: 'displayReview',
  templateUrl: 'review-update.html',
  styleUrls: ['./edit-template.component.css']
})
export class DisplayReviewDialog implements OnInit{
  constructor(
    public dialogRef: MatDialogRef<DisplayReviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: SectContents[]
  ){}
  ngOnInit(){
    this.data.forEach(sect => {
      sect.para.forEach(pa =>{
        pa.newQuestion = this.diffString(this.processHtml(pa.oldQuestion), this.processHtml(pa.newQuestion));
        pa.newGuidenote = this.diffString(this.processHtml(pa.oldGuidenote), this.processHtml(pa.newGuidenote));
      })
    });
  }

  onRemoveFlag(): void {
    this.dialogRef.close(true);
  }

  onClose(): void {
    this.dialogRef.close(null);
  }

  diffString( o, n ) : string{
    o = o.replace(/\s+$/, '');
    n = n.replace(/\s+$/, '');

    var out = this.diff(o == "" ? [] : o.split(/\s+/), n == "" ? [] : n.split(/\s+/) );
    var str = "";

    var oSpace = o.match(/\s+/g);
    if (oSpace == null) {
      oSpace = ["\n"];
    } else {
      oSpace.push("\n");
    }
    var nSpace = n.match(/\s+/g);
    if (nSpace == null) {
      nSpace = ["\n"];
    } else {
      nSpace.push("\n");
    }

    if (out.n.length == 0) {
        for (var i = 0; i < out.o.length; i++) {
          str += "<del>" + out.o[i] + oSpace[i] + "</del>";
        }
    } else {
      if (out.n[0].text == null) {
        for (n = 0; n < out.o.length && out.o[n].text == null; n++) {
          str += "<del>" + out.o[n] + oSpace[n] + "</del>";
        }
      }

      for ( var i = 0; i < out.n.length; i++ ) {
        if (out.n[i].text == null) {
          str += "<ins>" + out.n[i] + nSpace[i] + "</ins>";
        } else {
          var pre = "";

          for (n = out.n[i].row + 1; n < out.o.length && out.o[n].text == null; n++ ) {
            pre += "<del>" + out.o[n] + oSpace[n] + "</del>";
          }
          str += " " + out.n[i].text + nSpace[i] + pre;
        }
      }
    }

    return str;
  }

  processHtml(html: string) : string {
    var result = this.decodeHtml(html);
    return this.removeHrefPart(result);
  }

  decodeHtml(html: string):string{
    if(html == null) return "";
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  /**
   * Why: when compare questions in different version, we don't want to compary links.
   * @param html Remove the href part in the <a> tag.
   * @returns
   */
  removeHrefPart(html: string): string {
    var regex = /\s*href\s*=\s*['"][^'"]*['"]/gi;
    let result = html.replace(regex, "");

    return result;
  }

  diff( o, n ) {
    var ns = new Object();
    var os = new Object();

    for ( var i = 0; i < n.length; i++ ) {
      if ( ns[ n[i] ] == null )
        ns[ n[i] ] = { rows: new Array(), o: null };
      ns[ n[i] ].rows.push( i );
    }

    for ( var i = 0; i < o.length; i++ ) {
      if ( os[ o[i] ] == null )
        os[ o[i] ] = { rows: new Array(), n: null };
      os[ o[i] ].rows.push( i );
    }

    for ( var j in ns ) {
      if ( ns[j].rows.length == 1 && typeof(os[j]) != "undefined" && os[j].rows.length == 1 ) {
        n[ ns[j].rows[0] ] = { text: n[ ns[j].rows[0] ], row: os[j].rows[0] };
        o[ os[j].rows[0] ] = { text: o[ os[j].rows[0] ], row: ns[j].rows[0] };
      }
    }

    for ( var i = 0; i < n.length - 1; i++ ) {
      if ( n[i].text != null && n[i+1].text == null && n[i].row + 1 < o.length && o[ n[i].row + 1 ].text == null &&
           n[i+1] == o[ n[i].row + 1 ] ) {
        n[i+1] = { text: n[i+1], row: n[i].row + 1 };
        o[n[i].row+1] = { text: o[n[i].row+1], row: i + 1 };
      }
    }

    for ( var i = n.length - 1; i > 0; i-- ) {
      if ( n[i].text != null && n[i-1].text == null && n[i].row > 0 && o[ n[i].row - 1 ].text == null &&
           n[i-1] == o[ n[i].row - 1 ] ) {
        n[i-1] = { text: n[i-1], row: n[i].row - 1 };
        o[n[i].row-1] = { text: o[n[i].row-1], row: i - 1 };
      }
    }

    return { o: o, n: n };
  }
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.html',
  styleUrls: ['./edit-template.component.css']
})
export class ConfirmBuildDialog implements OnInit{
  unlockFlag? : boolean;
  build: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmBuildDialog>,
    @Inject(MAT_DIALOG_DATA) public  data: {build: string}
  ){}

  ngOnInit(){
    this.build = this.data.build;
    if(this.build === "X3.0"){
      this.unlockFlag = false;
    }
  }

  onConfirm(): void {
    if(this.unlockFlag ===  undefined){
      this.dialogRef.close(0);
      return;
    }
    if(this.unlockFlag === false){
      this.dialogRef.close(1);
      return;
    }
    this.dialogRef.close(2); // unlock the xcelerator.
   }

   onCancel(): void {
     this.dialogRef.close(-1);
   }

   onCheckboxClick(evt$){
     this.unlockFlag = evt$.checked;
   }
}

/**
 * Dialog window to display the state list.
 */
@Component({
  selector: 'state-list',
  templateUrl: 'state-list.html',
  styleUrls: ['./edit-template.component.css']
})
export class StateListDialog implements OnInit{
  stateChecked: string[];
  tips: string
  unlockFlag? : boolean;

  constructor(
    public dialogRef: MatDialogRef<StateListDialog>,
    @Inject(MAT_DIALOG_DATA) public  data: {selected: string, buildType: string, states:string[]}
  ) {}

  ngOnInit(){
    this.stateChecked = [];
    if(this.data.buildType == "X3.0"){
      this.unlockFlag = false;
    }

    if(this.data.selected.length > 0){
      var splitted = this.data.selected.split(",");
      splitted.forEach(x => this.stateChecked.push(x));
    }
    if(this.data.buildType == "X3.0" ||this.data.buildType == "csv"){
      this.tips = "*Please note when selecting multiple states you will receive separate files for each state.";
    }else if(this.data.buildType == "C3.0"){
      this.tips = "*Please note when selecting multiple states you will receive one combined file.";
    }else if(this.data.buildType == "reghub"){
      this.tips = "*Please note when selecting multiple states a combined register will be sent to RegHub.";
    }else if(this.data.buildType == "regcompare"){
      this.tips = "*Please choose up to <b>FIVE</b> jurisdictions to compare.";
    }
    else{
     this.tips = "*Please note for efficient Partner Integration, select one state only to include in your template. If other jurisdictions are needed, please copy this template and select the additional states one at a time.";
    }
  }

    onConfirm(): void {
      if(this.unlockFlag !== undefined){
        this.stateChecked.push(this.unlockFlag ? "1" : "0");
      }
     this.dialogRef.close(this.stateChecked);
    }

    onCancel(): void {
      this.dialogRef.close(null);
    }

    onStateClick(evt$, state: string){
      if(evt$.checked) {
        this.stateChecked.push(state);
      }else{
        const index = this.stateChecked.indexOf(state);
        if(index > -1){
          this.stateChecked.splice(index, 1);
        }
      }
    }

    onUnlockClick(evt$){
      this.unlockFlag = evt$.checked;
    }
}

/**
 * Dialog window to display the PreAnswerList Dialog.
 */
 @Component({
  selector: 'preAnswer',
  templateUrl: 'preAnswer.html',
  styleUrls: ['./edit-template.component.css']
})
export class PreAnswerListDialog implements OnInit{
  answerSelected = "";
  tips: string

  constructor(
    public dialogRef: MatDialogRef<PreAnswerListDialog>,
    @Inject(MAT_DIALOG_DATA) public  data: {answers:string[]}
  ) {}

  ngOnInit(){
  }

    onConfirm(): void {
      if(this.answerSelected.length === 0) {
        alert("Please choose the predefined answer list code for Intelex.");
        return;
      }
     this.dialogRef.close(this.answerSelected);
    }

    onCancel(): void {
      this.dialogRef.close(null);
    }

    radioChange(event: MatRadioChange){
      this.answerSelected = event.value;
    }
}